/*bootstrap*/
@import '_bootstrap_custom.scss';

/*variables*/
$black-color : #000;
$white-color : #FFF;

$yellow-color : #ffd400;
$yellow2-color : #ffb000;

$grey-color : #c8c8c8;
$grey2-color : #e5e5e5;
$grey3-color : #ededee;
$grey4-color : #cccccc;
$grey5-color : #89898b;

$red-color : #ce2a22;
$red2-color : #a3171f;

$green-color : #08B308;





$blue-color : #1a5092;
$blue2-color : #353d76;
$blue3-color : #dae3ea;




$yellow3-color : #fdf4ea;
$yellow4-color : #fadfa1;

$orange-color : #cf5a24;
$orange2-color : #e19873;

$blue4-color : #697185;
$blue5-color : #0f9cd7;


$grey5-color : #606060;
$grey6-color : #8e8e8e;
$grey7-color : #a6b5c9;
$grey8-color : #5d5d5d;

$pink-color : #e20057;

$red3-color : #ad1c1f;

$green2-color : #89b72b;

$red3-color : #9c0f06;
$red4-color : #e7212d;
$red5-color : #462323;
$red6-color : #a01b1c;
$red7-color : #950e05;
$red8-color : #b61114;
$red9-color : #c77f73;
$red10-color : #eb212e;
$red11-color : #c36757;

$gold-color : #fcc059;
$gold2-color : #eeb351;
$gold3-color : #fdebd0;
$gold4-color : #eaac4b;

/*function*/
$mobile_base_size: 640;
$mobile_landscape_base_size: 1024;
$tab_base_size : 768;
$desktop_base_size : 1920;

$mobile_padding : 30px;
$tab_padding : 15px;
$desktop_padding : 40px;

/* retourne une valeur en vw correspondant a la valeur de base du device*/
@function get-desktop-vw($size){

    @if(index('px', unit($size)) == null){
        $size : $size * 1px;
    }

    $vw-context: ($desktop_base_size*.01) * 1px;
    @return ($size/$vw-context) * 1vw;
}

/* IDEM pour tablette */
@function get-tab-vw($size){

    @if(index('px', unit($size)) == null){
        $size : $size * 1px;
    }

    $vw-context: ($tab_base_size*.01) * 1px;
    @return ($size/$vw-context) * 1vw;
}

/* IDEM pour mobile */
@function get-mobile-vw($size){

    @if(index('px', unit($size)) == null){
        $size : $size * 1px;
    }

    $vw-context: ($mobile_base_size*.01) * 1px;
    @return ($size/$vw-context) * 1vw;
}

/* IDEM pour mobile landscape*/
@function get-mobile-landscape-vw($size){

    @if(index('px', unit($size)) == null){
        $size : $size * 1px;
    }

    $vw-context: ($mobile_landscape_base_size*.01) * 1px;
    @return ($size/$vw-context) * 1vw;
}

/* taille des marge des container boostrap en fonction du device :
- MOBILE -> max width MD
- TABLETTE -> max width LG 
- DESKTOP -> max width auto
*/

@mixin boostrap_gutter_size($container_padding){

    .container,
    .container-fluid,
    .col,
    [class*="col-"]
    {
        padding-left : $container_padding;
        padding-right : $container_padding;
    }

    .row{
        margin-right: -$container_padding;
        margin-left: -$container_padding;

        &.no-gutters{
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    /* PROPRE A L'OPE ALVITYL */
    .container-fluid{
        padding-left : 0px !important;
        padding-right : 0px !important;
    }

}

@media screen and (max-width : map-get($grid-breakpoints, md)){ 
    @include boostrap_gutter_size(get-mobile-vw($mobile_padding));
}
@media screen and (min-width : map-get($grid-breakpoints, md)) and (max-width : map-get($grid-breakpoints, lg)){
    @include boostrap_gutter_size(get-tab-vw($tab_padding));
}
@media screen and (min-width : map-get($grid-breakpoints, lg)){
    @include boostrap_gutter_size($desktop_padding);
}

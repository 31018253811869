@import 'global';

//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-BlackItalic.woff2') format('woff2'),
	url('../font/cera/CeraPro-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-Medium.woff2') format('woff2'),
	url('../font/cera/CeraPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-Light.woff2') format('woff2'),
	url('../font/cera/CeraPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-Bold.woff2') format('woff2'),
	url('../font/cera/CeraPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-Black.woff2') format('woff2'),
	url('../font/cera/CeraPro-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../font/cera/CeraPro-Italic.woff2') format('woff2'),
	url('../font/cera/CeraPro-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

.ff-cera{ font-family: 'Cera Pro'; }

.fw-thin{ font-weight: 100; }
.fw-extra-light{ font-weight: 200; }
.fw-light{ font-weight: 300; }
.fw-regular{ font-weight: 500; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }

.fc-white{	color : $white-color; }

/*génération des taille de polices mobile*/
$mobile_font_size : 35,24,20;

@each $i in $mobile_font_size {

	.fs-#{$i}{
		font-size : get-mobile-vw(1px*$i);
	}
}

/*génération des taille de polices small desktop */
@media screen and (min-width : map-get($grid-breakpoints, lg)) and (max-width : ($desktop_base_size - 1) * 1px ){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : get-desktop-vw(1px*$i);
		}
	}

}

/*génération des taille de polices desktop */
@media screen and (min-width : $desktop_base_size * 1px){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : 1px*$i;
		}
	}

}